@import  
'./utils/reset',
'./utils/mixins',
'./utils/variables',
'./components/scroll',
'./components/header',
'./components/buttons',
'./components/icons',
'./components/grid',
'./components/animation',
'./components/decor',
'./components/footer',
'./pages/frontlight',
'./pages/singlepost',
'./pages/page';

* { 
	margin:0;
    font-family: $primary-font;
}

body { 
	color:$secondary-color;
	font-size:0.9rem;
    min-height: 100vh;
}

a, a:hover {
  text-decoration: none;
}

p {
  text-align:justify;
}