/* Colors */

$main-color: hsl(0%,0%,10%);
$secondary-color: hsl(0%,0%,90%);
$accent-color: #6f2232;
$purple-color: #792DC3;
$purple-light-color: #ead3ff;
$extra-color:#950740;
$gray-color: gray;
$text-color: #3B3C4A;
$black: hsl(0%,0%,0%);
$bright-color: #6f2232;

/* Fonts */

$primary-font: 'Montserrat', sans-serif;
$secondary-font: 'Open Sans', sans-serif;

/* Breakpoints */

$bk-phone-small: 639px;
$bk-phone: 767px;
$bk-tablet: 1023px;
$bk-desktop-small: 1199px;
$bk-desktop-medium: 1439px;
$bk-desktop-large: 1799px;
$bk-desktop-xlarge: 1919px